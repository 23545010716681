import HeaderScroll from './shared/headerScroll';
//import VideoCustom from './shared/videoCustom';
import SwiperHome from './shared/swiperHome';
import TabMenu from './shared/tabMenu';

export default class Main {

    constructor() {
        this.init();
    }

    init() {

		this.addListeners();

    }

    addListeners() {
        document.addEventListener("DOMContentLoaded", function() {
            HeaderScroll.init();
            //VideoCustom.init();
            //SwiperHome.init();
            TabMenu.init();
        });
        
    }


}

const main = new Main();
