export default class headerScroll {

    static init() {



        $(window).on('scroll', function(){
            //caches a jQuery object containing the header element
            var header = $("header");
            var bookTable = $(".section--book-table");

                var scroll = $(window).scrollTop();
                if (scroll >= 50) {
                    header.addClass("scrollHeader");
                    bookTable.addClass("scrollHeader");
                } else {
                    header.removeClass("scrollHeader");
                    bookTable.removeClass("scrollHeader");
                }
        });


    }

}
