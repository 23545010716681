export default class tabMenu {

    static init() {

        $( "#tab-menu" ).tabs();
        /*$(".tab-burger").on("click", function(){
            $(".section--gallery").removeClass("bg-acqua");                                 
         });
        $(".tab-kitchen").on("click", function(){
           $(".section--gallery").addClass("bg-acqua");                                 
        });*/

    }

}
